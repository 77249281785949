import { FC } from 'react';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import Link from 'next/link';

type SuccessToastProps = {
  title?: string;
  detail: string;
  type?: 'embed' | 'default';
  link?: string;
};

export const SuccessToast: FC<SuccessToastProps> = ({
  title,
  detail,
  type = 'default',
  link,
}) => {
  return (
    <div
      className={cn(
        'pointer-events-auto w-full overflow-hidden rounded-lg border border-gray-900/10 bg-[rgb(31,41,55)] shadow-lg',
        {
          ['max-w-sm']: type === 'default',
          ['m-auto max-w-[75%]']: type === 'embed',
        },
      )}
    >
      <div
        className={cn('relative', {
          ['p-4']: type === 'default',
          ['p-3']: type === 'embed',
        })}
      >
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-1">
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <div className="ml-3 w-0 flex-1">
            {title && (
              <p className="text-base font-medium leading-6 text-white">
                {title}
              </p>
            )}
            <p
              className="mt-1 text-sm leading-5 text-gray-300"
              data-testid="success-toast-message"
            >
              {detail}
            </p>
            {link && (
              <Link
                href={link}
                className="mt-2 text-xs text-emerald-400 underline hover:text-emerald-300"
              >
                View
              </Link>
            )}
          </div>
        </div>

        {type !== 'embed' && (
          <div className="absolute right-4 top-4">
            <XMarkIcon
              className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-300"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessToast;
