import { FC, HTMLAttributeAnchorTarget } from 'react';

import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import cn from 'classnames';
import Link from 'next/link';

import { sendClientSidePosthogEvent } from '@/utils/posthog';

type WarningToastProps = {
  title?: string;
  detail: string;
  type?: 'embed' | 'default';
  upgradeButton?: boolean;
  target?: HTMLAttributeAnchorTarget;
  dangerouslySetInnerHTML?: boolean;
};
const WarningToast: FC<WarningToastProps> = ({
  title,
  detail,
  upgradeButton = false,
  target = '_self',
  type = 'default',
  dangerouslySetInnerHTML,
}) => {
  return (
    <div
      className={cn(
        'pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
        {
          ['max-w-sm']: type === 'default',
          ['m-auto max-w-[75%]']: type === 'embed',
        },
      )}
    >
      <div
        className={cn('relative', {
          ['p-4']: type === 'default',
          ['p-3']: type === 'embed',
        })}
      >
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            {title && (
              <p className="mb-1 text-sm font-medium text-gray-900">{title}</p>
            )}
            {dangerouslySetInnerHTML ? (
              <p
                className="text-sm text-gray-500 [&>a]:text-blue-700"
                data-testid="warning-toast-message"
                dangerouslySetInnerHTML={{ __html: detail }}
              />
            ) : (
              <p
                className="text-sm text-gray-500"
                data-testid="warning-toast-message"
              >
                {detail}
              </p>
            )}

            {upgradeButton ? (
              <Link
                href="/dashboard/profile/billing"
                className="mt-2 flex text-sm font-medium text-indigo-600"
                target={target}
                onClick={() => {
                  sendClientSidePosthogEvent('app_billing_click', {
                    from: 'upgrade_toast_click',
                  });
                }}
              >
                Upgrade
              </Link>
            ) : null}
          </div>
        </div>
        {type !== 'embed' && (
          <div className="absolute right-4 top-4">
            <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
};

export default WarningToast;
